import React from 'react';
import * as S from './Wardrobe.styled';
import GridBackground from '../../common/GridBackground/GridBackground';
import Closet from './Closet/Closet';

const WardrobePage: React.FC<{}> = () => {
  return (
    <S.Container>
      <GridBackground />
      <Closet />
    </S.Container>
  );
}

export default WardrobePage;