import React, { useRef, useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Routes, Route, useLocation, Navigate } from 'react-router';
import * as S from './App.styled';
import HomePage from '../pages/Home/Home';
import WardrobePage from '../pages/Wardrobe/Wardrobe';
import { ThemeProvider } from 'styled-components';
import { useAppDispatch, useAppSelector } from './hooks';
import { selectTheme } from '../features/theme/themeSlice';
import Overlay from '../common/Overlay/Overlay';
import Sonny2048Page from '../pages/Sonny2048/Sonny2048';
import { userCheck } from '../features/user/userSlice';

const App: React.FC<{}> = () => {

  // check for a user on app initialization
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(userCheck());
  }, [dispatch]);

  // Keep track of the user's theme selection
  const theme = useAppSelector(selectTheme);

  // Location state manager for between-page transitions
  const location = useLocation();
  const nodeRef = useRef(null); 
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransitionStage] = useState('enter');
  useEffect(() => {
    if (location.pathname !== displayLocation.pathname) {
      setTransitionStage('exit');
    }
  }, [location, displayLocation]);  

  return (
    <ThemeProvider theme={theme}>
      <S.GlobalStyle {...theme} />
      <CSSTransition
        appear
        timeout={300}
        nodeRef={nodeRef}
        in={transitionStage !== 'exit'}
        classNames='location'
        onEntered={()=> setTransitionStage('entered')}
        onExited={() => {
          setTransitionStage('enter');
          setDisplayLocation(location);
        }}>
        <S.AppContainer ref={nodeRef}>
          <Overlay />
          <Routes location={displayLocation}>
            <Route path="/wardrobe" element={<WardrobePage />} />
            <Route path="/sonny" element={<Sonny2048Page />} />
            <Route path="/" element={<HomePage />} />
            <Route path="*" element={<Navigate replace to="/" />}/>
          </Routes>
        </S.AppContainer>
      </CSSTransition>
    </ThemeProvider>
  );
}

export default App;
