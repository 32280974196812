import styled from 'styled-components';
import { media } from '../../../assets/mixins';

export const Container = styled.div<{active?: boolean}>`
  position: fixed;
  height: calc(100% - 150px);
  width: calc(100% - 30px);
  top: 80px;
  left: 0px;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  border: 2px ${({ theme }) => theme.background2} dashed;
  border-left: none;
  padding: 10px;
  padding-left: 0;

  // opacity: ${({ active }) => active ? `1` : `0`};
  transform: ${({ active }) => active ? `translateX(0)` : `translateX(calc(-100% + 15px))`};

  ${media.laptop`
    width: 50%;
  `}

  .location-enter & {
    opacity: 0;
    transform: translateX(calc(-110%));
  }

  .location-enter-active & {
    opacity: 1 ;
    translateX(calc(-100% + 15px));
  }

  .location-exit & {
    opacity: 1;
    translateX(calc(-100% + 15px));
  }

  .location-exit-active & {
    opacity: 0;
    transform: translateX(calc(-110%));
  }
`;

export const ClosetText = styled.div`
  position: absolute;
  right: calc(var(--base-unit) * -5);
  transform-origin: 50% 50%;
  transform: rotate(-90deg);
  transition: all 0.2s ease-in-out;
  line-height: 1;
  cursor: pointer;
  pointer-events: all;
  font-size: calc(var(--base-unit) * 2);
  color: ${({ theme }) => theme.text};

  &:hover {
    transform: rotate(-90deg) scale(1.2);
  }
`;

export const Closet = styled.div`
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  background-color: ${({ theme }) => theme.background2}30;
  border: 3px ${({ theme }) => theme.text} dotted;
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;