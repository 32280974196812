import React, { useEffect } from 'react';
import * as S from './Sonny2048.styled';
import { Helmet } from 'react-helmet-async';

import GridBackground from '../../common/GridBackground/GridBackground';

const Sonny2048Page: React.FC<{}> = React.memo(() => {

  useEffect(() => {
    return () => {
      // @ts-ignore
      gamemanager.inputManager.clearBindings(); // clears the game's bindings
    }
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link href="style/sonny.css" rel="stylesheet" type="text/css" />
        <script src="js/sonny.js"/>
      </Helmet>
      <GridBackground />
      <S.Container>
        <S.TitleContainer>
          {/* <S.TitleRow>
            <S.Title src={SONNY} />
            <S.Title src={_2048} />
          </S.TitleRow> */}
        </S.TitleContainer>
        <S.ContentContainer>
          <S.InfoContainer>
            <S.Heading>
              <S.ScoresContainer>
                <S.ScorePoints>
                  <S.ScorePointsTitle>SCORE</S.ScorePointsTitle>
                  <S.ScorePointsValueText id="score-points">0</S.ScorePointsValueText>
                </S.ScorePoints>
                <S.ScorePoints>
                  <S.ScorePointsTitle>BEST</S.ScorePointsTitle>
                  <S.ScorePointsValueText id="best-points">0</S.ScorePointsValueText>
                </S.ScorePoints>
                <S.ScorePoints>
                  <S.ScorePointsTitle>ANGEL</S.ScorePointsTitle>
                  <S.ScorePointsValue>
                    <S.ScorePointsValueText id="score-container">None</S.ScorePointsValueText>
                  </S.ScorePointsValue>
                </S.ScorePoints>
                <S.ScorePoints>
                  <S.ScorePointsTitle>ANGEL MAX</S.ScorePointsTitle>
                  <S.ScorePointsValue>
                    <S.ScorePointsValueText id="best-container">None</S.ScorePointsValueText>
                  </S.ScorePointsValue>
                </S.ScorePoints>
              </S.ScoresContainer>
            </S.Heading>
            <S.AboveGame>
              <S.GameIntro>Join the Angels!</S.GameIntro>
              <S.RestartButton id="restart-button">New Game</S.RestartButton>
            </S.AboveGame>
          </S.InfoContainer>
          <div className="game-container">
            <div className="game-message">
              <p></p>
              <div className="lower">
                <span className="keep-playing-button">Keep going</span>
                <span className="retry-button">Retry</span>
                <div className="score-sharing"></div>
              </div>
            </div>

            <div className="grid-container">
              <div className="grid-row">
                <div className="grid-cell"></div>
                <div className="grid-cell"></div>
                <div className="grid-cell"></div>
                <div className="grid-cell"></div>
              </div>
              <div className="grid-row">
                <div className="grid-cell"></div>
                <div className="grid-cell"></div>
                <div className="grid-cell"></div>
                <div className="grid-cell"></div>
              </div>
              <div className="grid-row">
                <div className="grid-cell"></div>
                <div className="grid-cell"></div>
                <div className="grid-cell"></div>
                <div className="grid-cell"></div>
              </div>
              <div className="grid-row">
                <div className="grid-cell"></div>
                <div className="grid-cell"></div>
                <div className="grid-cell"></div>
                <div className="grid-cell"></div>
              </div>
            </div>

            <div className="tile-container">

            </div>
          </div>
        </S.ContentContainer>

        <div style={{ display: 'none' }}>
          <p className="game-explanation">
            <strong className="important">How to play:</strong> Use your <strong>arrow keys</strong> to move the tiles. When two angels with the same symbol touch, they <strong>get promoted!</strong>
          </p>
          <hr />
          <p className="tile-legend"><strong className="important">Tile Legend:</strong></p>
          <hr />
          <p>
            Refurbished with love. For Masha.
          </p>
          <br />
          <br />
          <br />
        </div>
      </S.Container>
    </>
  );
});

export default Sonny2048Page;