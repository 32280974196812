import { UsernamePasswordOpts } from '@aws-amplify/auth/lib-esm/types';
import { Auth } from 'aws-amplify';

export interface UserData {
  theme: number;
  name: string;
  username: string;
}

export interface UserChallenge {
  user: any;
  newPassword: string;
  requiredAttributes: {[index: string]: string};
}

const UserService = {

  // signs in the user. may return a challenge!
  login(fields: UsernamePasswordOpts): Promise<any> {
    return Auth.signIn(fields);
  },

  // performs the challenge for the passed-in user
  challenge(data: UserChallenge) {
    const { user, newPassword, requiredAttributes } = data;
    return Auth.completeNewPassword(user, newPassword, requiredAttributes);
  },

  // signs out the user
  logout() {
    return Auth.signOut();
  },

  // gets the current user
  current(): Promise<UserData> {
    return Auth.currentAuthenticatedUser();
  }
}

export default UserService;