import { UsernamePasswordOpts } from '@aws-amplify/auth/lib-esm/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import UserService, { UserChallenge, UserData } from './user.services';

/* -------------------------------------------------------------------------- */
/*                              TYPE DECLARATIONS                             */
/* -------------------------------------------------------------------------- */

interface UserState {
  user?: UserData;
}

const initialState: UserState = {
  user: undefined
}

/* -------------------------------------------------------------------------- */
/*                               THUNKED ACTIONS                              */
/* -------------------------------------------------------------------------- */

// login a user
export const userLogin = createAsyncThunk<any, UsernamePasswordOpts>(
  'user/login',
  async (fields) => UserService.login(fields)
);

// uploads user's new password
export const userNewPassword = createAsyncThunk<any, UserChallenge>(
  'user/newPassword',
  async (fields) => UserService.challenge(fields)
);

// get the current user
export const userCheck = createAsyncThunk<any>(
  'user/check',
  async () => UserService.current()
);

// logout a user
export const userLogout = createAsyncThunk<void>(
  'user/logout',
  async () => UserService.logout()
)

/* -------------------------------------------------------------------------- */
/*                               SLICE CREATION                               */
/* -------------------------------------------------------------------------- */

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // add reducers for additional action types

    // if user login is fulfilled and not challenged, set the state
    builder.addCase(userLogin.fulfilled, (state, action) => {
      if (action.payload.challengeName !== "NEW_PASSWORD_REQUIRED") {
        const { username, attributes } = action.payload;
        state.user = {
          username: username,
          theme: attributes["custom:theme"],
          name: attributes.name
        }
      }
    });

    // if user new password is fulfilled, set the state
    builder.addCase(userNewPassword.fulfilled, (state, action) => {
      const { username, attributes } = action.payload;
      state.user = {
        username: username,
        theme: attributes["custom:theme"],
        name: attributes.name
      }
    });

    // if user check is fulfilled, set the state
    builder.addCase(userCheck.fulfilled, (state, action) => {
      const { username, attributes } = action.payload;
      state.user = {
        username: username,
        theme: attributes["custom:theme"],
        name: attributes.name
      }
    });

    // if user check is rejected, remove the current state
    builder.addCase(userCheck.rejected, (state) => {
      state.user = undefined;
    });

    // if user logout is fulfilled, remove the current state
    builder.addCase(userLogout.fulfilled, (state) => {
      state.user = undefined;
    });
  }
})

/* -------------------------------------------------------------------------- */
/*                               EXPORTING TOOLS                              */
/* -------------------------------------------------------------------------- */

export default userSlice.reducer;

export const userExists = (state: RootState) => !!state.user.user;
export const selectUser = (state: RootState) => state.user.user;