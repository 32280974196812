import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  z-index: 1;
  min-height: 3000px;
  transition: opacity 0.3s ease-in-out;

  .location-enter & {
    opacity: 0;
  }

  .location-enter-active & {
    opacity: 1;
  }

  .location-exit & {
    opacity: 1;
  }

  .location-exit-active & {
    opacity: 0;
  }
`;