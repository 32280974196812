import { combineReducers } from "@reduxjs/toolkit";
import themeReducer from '../features/theme/themeSlice';
import userReducer from '../features/user/userSlice';
import modalReducer from '../features/modal/modalSlice';

const rootReducer = combineReducers({
  theme: themeReducer,
  modal: modalReducer,
  user: userReducer
});

export default rootReducer;