import styled from 'styled-components';
import { media } from '../../assets/mixins';

export const Container = styled.div`
  color: #E84855;
  font-size: 18px;
  width: 280px;
  height: 100%;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background: url("img/bg.png") top center no-repeat, url("img/bg2.png") top center no-repeat;

  & p {
    font-size: calc(var(--base-unit) * 2);
  }

  & p:last-child {
    font-family: "Pico Pixel";
  }
  transition: opacity 0.3s ease-in-out;


  .location-enter & {
    opacity: 0;
  }

  .location-enter-active & {
    opacity: 1;
  }

  .location-exit & {
    opacity: 1;
  }

  .location-exit-active & {
    opacity: 0;
  }

  & .game-container {
    background: ${({ theme }) => theme.background2};
  }

  & .game-container .game-message span {
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text };
  }
`;

export const Heading = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  ${media.tablet`
  flex-direction: row;
  `}
`;

export const Title = styled.img`
  height: calc(var(--base-unit) * 14);
  width: auto;
  transform-origin: 50% 50%;
  transition: transform 0.2s ease-in-out;
  z-index: 2;

  &:hover {
    transform: scale(1.1);
  }
`;

export const TitleRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.tablet`
  flex-direction: row;
  gap: var(--base-unit);
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  line-height: 1.2;
  // padding: 20px 0px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 500px;

  ${media.tablet`
  justify-content: space-between;
  `}
`;

export const ScoresContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 6px;
  flex-wrap: wrap;

  ${media.tablet`
  flex-direction: column;
  margin-right: 20px;
  `}
`;

export const ScorePoints = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 40%;
  background-color: ${({ theme }) => theme.background3 };
  border-radius: 5px;
  position: relative;
  flex: 1;
  padding: 8px 0px;

  & .score-addition {
    position: absolute;
    /*right: 60px;*/
    width: 100%;
    text-align: center;
    color: red;
    font-size: 19px;
    line-height: 19px;
    font-family: "Pico Pixel";
    font-size: calc(var(--base-unit)*2);
    color: ${({ theme }) => theme.text3 }80;
    z-index: 100;
    -webkit-animation: move-up 600ms ease-in;
    -moz-animation: move-up 600ms ease-in;
    animation: move-up 600ms ease-in;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    animation-fill-mode: both; }
  }

  ${media.tablet`
  min-width: 0%;
  `}
`;

export const ScorePointsTitle = styled.div`
  font-family: "Pico Pixel";
  font-size: calc(var(--base-unit)*2);
  color: ${({ theme }) => theme.text3 };
  line-height: 1.2;
`;

export const ScorePointsValue = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(var(--base-unit)*4.8);
  overflow: hidden;
  text-align: center;
`;

export const ScorePointsValueText = styled.div`
  font-family: "Pico Pixel";
  font-size: calc(var(--base-unit)*3);
  color: white;
  line-height: calc(var(--base-unit)*2.4);
  padding: 0 10px;
`;

export const AboveGame = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
`;

export const GameIntro = styled.div`
  font-family: "Pico Pixel";
  font-size: calc(var(--base-unit) * 2.7);
  padding-top: 5px;
  line-height: 1.2;
  z-index: 2;
  color: ${({ theme }) => theme.text2};

  ${media.tablet`
  margin: 0 auto;
  `}
`;

export const RestartButton = styled.div`
  background-color: ${({ theme }) => theme.background3 };
  font-family: "Pico Pixel";
  font-size: calc(var(--base-unit) * 2.5);
  color: white;
  border-radius: 3px;
  padding: 10px 20px;
  padding-bottom: 5px;
  line-height: 1;
  transform-origin: 50% 50%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  z-index: 2;

  &:hover {
    transform: scale(1.1);
  }

  ${media.tablet`
  margin: 0 auto;
  `}
`;

export const GameMessage = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 100;
  text-align: center;
  -webkit-animation: fade-in 800ms ease 1200ms;
  -moz-animation: fade-in 800ms ease 1200ms;
  animation: fade-in 800ms ease 1200ms;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;

  & p {
    font-size: 30px !important;
    height: 30px !important;
    line-height: 30px !important;
    margin-top: 90px !important;
    color: ${({ theme }) => theme.background3}
  }

  & .lower {
    display: block;
    margin-top: 30px;
  }

  &.game-over, &.game-won {
    display: block;
  }
  
  &.game-won {
    background: rgba(88, 46, 116, 0.5);
    color: white;
  }

  &.game-won .score-sharing {
    margin-top: 10px;
  }

  ${media.mobile`
    & p {
      font-size: 60px;
      font-weight: bold;
      height: 60px;
      line-height: 60px;
      margin-top: 222px;
    }

    & .lower {
      margin-top: 59px;
    }

    &.game-won .score-sharing {
      margin-top: 0px;
    }
  `}
`;