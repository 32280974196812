import styled from 'styled-components';
import { ThemeData } from '../../features/theme/theme.services';

export const Container = styled.div`
  position: relative;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  justify-content: flex-begin;
  align-items: center;
`;

export const MainThemeButton = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #606060;
  background-color: ${({ theme }) => theme.background};
  transform-origin: 50% 50%;
  cursor: pointer;
  pointer-events: all;
  transition: all 0.2s ease-in-out;
  z-index: 2;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 2px;
  padding-top: 4px;
  line-height: 1;
  font-family: "Pico Pixel";

  &:hover {
    transform: scale(1.1);
  }
`;

export const SubThemeButton = styled.div<{custTheme: ThemeData}>`
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.background2 };
  color: ${({ custTheme }) => custTheme.text };
  background-color: ${({ custTheme }) => custTheme.background };
  transform-origin: 50% 50%;
  cursor: pointer;
  pointer-events: all;
  transition: all 0.3s ease-in-out;
  z-index: 2;
  margin-top: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 2px;
  padding-top: 4px;
  line-height: 1;
  font-family: "Pico Pixel";
  font-family: "Pico Pixel";

  &:hover {
    transform: scale(1.1);
  }

  .themeswatch-appear & {
    transform: translateX(20px);
    opacity: 0;
  }

  .themeswatch-appear-active & {
    transform: translateX(0px);
    opacity: 1;
  }

  .themeswatch-enter & {
    transform: translateX(20px);
    opacity: 0;
  }

  .themeswatch-enter-active & {
    transform: translateX(0px);
    opacity: 1;
  }

  .themeswatch-exit & {
    transform: translateX(0px);
    opacity: 1;
  }

  .themeswatch-exit-active & {
    transform: translateX(20px);
    opacity: 0;
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ThemeContainer = styled.div``;