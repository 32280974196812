import styled, { createGlobalStyle } from 'styled-components';
import { ThemeData } from '../features/theme/theme.services';
import { media } from '../assets/mixins';
import { returnBreakpoint } from '../assets/breakpoints';

export const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
`;

export const GlobalStyle = createGlobalStyle<Pick<ThemeData, Exclude<keyof ThemeData, 'backgroundImages'>>>`
  html,
  body {
    ${({ background, text }) => `
    background-color: ${background};
    color: ${text};
    `}
    width: 100%;
    height: 100%;
  }

  #root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    user-select: none;
  }

  ul, ol {
    margin-top: 0px;
    padding-top: 0px;
    list-style-type: none;
    list-style-position: initial;
    list-style-image: initial;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  :root {
    /* COLORS */
    --black: #1e1e1e;
    --gray: #C2C2C2;
    --white: #fff;
    --bg-color: #202020;
    --overlay: rgba(0, 0, 0, 0.85);
    --overlay-light: rgba(0, 0, 0, 0.35);
    --border-black: 1px solid var(--black);
    --border-light: 1px solid #dbdbdb;

    /* FONTS */
    --font-a: "Pico Pixel", Helvetica, Arial, sans-serif;
    --font-b: Courier, monospace;
    
    /* SPACING */
    --base-unit: 8px;
    --space-sm: calc(var(--base-unit) * 2);
    --space-md: calc(var(--base-unit) * 3);
    --space-lg: calc(var(--base-unit) * 5);

    /* TYPOGRAPHY */
    --text-01: calc(var(--base-unit) * 1.5);
    --text-02: calc(var(--base-unit) * 2);
    --text-03: calc(var(--base-unit) * 3);
    --text-04: calc(var(--base-unit) * 4);
    --text-05: calc(var(--base-unit) * 6);

    /* LAYOUT */
    --header-z: 100;
    --header-height: calc(var(--base-unit) * 15);
    --footer-height: calc(var(--base-unit) * 5);
    --content-width-md: 960px;
    --content-width-lg: ${returnBreakpoint('desktop')};
    --content-width-xl: ${returnBreakpoint('xl')};
  }

  /* MEDIA QUERY MIXIN */

  ${media.laptop`
    :root {
      --base-unit: 10px;
      --text-05: calc(var(--base-unit) * 6);
    }
  `}

  ${media.xl`
    :root {
      --base-unit: 11px;
      --text-05: calc(var(--base-unit) * 7);
    }
  `}

  /* DEFAULTS */
  /* LAYOUT */
  html,
  body,
  #__next {
    min-height: 100%;
  }

  html,
  body {
    height: 100%;
  }

  body * {
    font-family: var(--font-a);
  }

  main {
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    height: 100%;
    min-height: 100%;
  }

  header,
  footer {
    font-size: var(--text-02);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px calc(var(--base-unit) * 5);
    a {
      text-decoration: none;
      color: var(--gray);
      transition: color 0.2s ease-in-out;
      &.active {
        color: var(--black);
      }
      ${media.hover`
        color: var(--black);
      `}
    }
  }

  /* TYPOGRPAHY */
  h1,h2,h3,h4,h5,h6 {
    font-weight: 500;
  }
  h1 {
    font-size: calc(var(--text-05)*2);
    line-height: 1;
    text-align: center;
    padding: var(--space-md) 0 var(--space-lg);
  }
  h2 {
    font-size: var(--text-03);
    padding: var(--space-sm) 0;
  }
  h3 {
    font-size: var(--text-03);
    padding: var(--space-sm) 0;
  }
  a {
    font-weight: 400;
    text-decoration: none;
  }
  p,ol,ul {
    font-size: var(--text-01);
    padding-bottom: var(--space-sm);
    line-height: 1.35;
    font-weight: 400;
  }

  /* HIDE SCROLLBARS */
  *::-webkit-scrollbar {
    display: none;
  }

  * {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
`;
