import React, { useState } from 'react';
import { useLocation } from 'react-router';
import * as S from './Closet.styled';

const Closet: React.FC<{}> = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  return (
    <S.Container active={open && location.pathname === '/wardrobe'}>
      <S.ClosetText onClick={() => setOpen(!open)}>CLOSET</S.ClosetText>
      <S.Closet>

      </S.Closet>
    </S.Container>
  );
}

export default Closet;