import React, { useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import * as S from './ThemePicker.styled';
import { THEMES } from '../../features/theme/theme.services';
import { selectThemeI, setTheme } from '../../features/theme/themeSlice';

const ThemePicker: React.FC<{}> = React.memo(() => {

  // toggling states
  const nodeRef = useRef<HTMLDivElement>(null);
  const nodeRef2 = useRef<HTMLDivElement>(null);
  const [toggled, setToggled] = useState(false);
  const currTheme = useAppSelector(selectThemeI);
  // use dispatch to allow changing the theme
  const dispatch = useAppDispatch();

  return (
    <CSSTransition
      appear
      classNames="themeswatch"
      in={toggled}
      nodeRef={nodeRef}
      timeout={300}>
      <S.Container ref={nodeRef}>
        <S.MainThemeButton onClick={() => setToggled(!toggled)}>{currTheme}</S.MainThemeButton>
        <CSSTransition
          in={toggled}
          nodeRef={nodeRef2}
          timeout={300}
          mountOnEnter
          unmountOnExit
          >
          <S.ThemeContainer ref={nodeRef2}>
            {THEMES.map((theme, i) => (i !== currTheme ? 
            <S.SubThemeButton 
              key={theme.name} 
              custTheme={theme}
              onClick={() => {
                dispatch(setTheme(i));
                setToggled(false);
              }}>{i}</S.SubThemeButton> : null))}
            <S.Backdrop onClick={() => setToggled(false)} />
          </S.ThemeContainer>
        </CSSTransition>
      </S.Container>
    </CSSTransition>
  );
});

export default ThemePicker;