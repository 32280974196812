import styled from 'styled-components';
import { media } from '../../assets/mixins';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 4;
  transition: opacity 0.3s ease-in-out;
  max-width: 1500px;
  position: relative;

  .location-enter & {
    opacity: 0;
  }

  .location-enter-active & {
    opacity: 1;
  }

  .location-exit & {
    opacity: 1;
  }

  .location-exit-active & {
    opacity: 0;
  }
`;

export const WardrobeImg = styled.img<{ loaded?: boolean }>`
  position: fixed;
  bottom: -10px;
  left: 50%;
  height: auto;
  width: 80%;
  max-width: 600px;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  filter: drop-shadow(0 0 0.75rem ${({ theme }) => theme.background2 });
  transform: translate(-50%, ${({loaded}) => loaded ? '0px' : '20px'});
  z-index: 3;

  opacity: ${({loaded}) => loaded ? '1' : '0'};
`;

// export const NavLink = styled.h1`
//   position: absolute;
//   top: 40%;
//   left: 40px;
//   line-height: 1;
//   transform-origin: 50% 50%;
//   transform: translateX(calc(-50% + 100px)) rotate(-90deg);
// `;

export const NavLink = styled.h2<{ left?: boolean }>`
  position: absolute;
  top: 50%;
  color: ${({ theme }) => theme.text};
  ${({ left }) => left ? `
  left: 0;
  ${media.mobile`
    left: 50px;
  `}
  ` : `
  right: 0;
  ${media.mobile`
    right: 50px;
  `}
  `}
  writing-mode: vertical-lr;
  text-orientation: upright;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;
  pointer-events: all;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    letter-spacing: 5px;
  }
`;

export const Maria = styled.div`
  font-size: calc(var(--base-unit) * 10);
  transform-origin: 50% 50%;
  transform: translateX(-50%) rotate(-10deg);
  position: fixed;
  top: 20%;
  left: 50%;
  text-align: center;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.text};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translateX(-50%) rotate(-10deg) scale(1.1);
  }

  ${media.mobile`
  font-size: calc(var(--base-unit) * 15);
  `}

  ${media.tablet`
  font-size: calc(var(--base-unit) * 20);
  `}
`;

export const TitleSpan = styled.div<{ left?: boolean }>`
  transition: all 0.3s ease-in-out;

  .location-enter & {
    opacity: 0;
    transform: translateX(${({ left }) => left ? '-50px' : '50px'});
  }

  .location-enter-active & {
    opacity: 1;
    transform: translateX(0px);
  }

  .location-exit & {
    opacity: 1;
    transform: translateX(0px);
  }

  .location-exit-active & {
    opacity: 0;
    transform: translateX(${({ left }) => left ? '-50px' : '50px'});
  }
`;

export const DotNet = styled.div`
  font-size: calc(var(--base-unit) * 5);
  transition: all 0.3s ease-in-out;
  color: ${({ theme }) => theme.text3 };
  opacity: 0.5;
  transform: translateY(-10px);

  .location-enter & {
    opacity: 0;
    transform: translateY(10px);
  }

  .location-enter-active & {
    opacity: 0.5;
  }

  .location-exit & {
    opacity: 0.5;
  }

  .location-exit-active & {
    opacity: 0;
    transform: translateY(10px);
  }
`;