import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { closeModal, passwordModal, selectModal } from '../../features/modal/modalSlice';
import { userLogin, userLogout, userNewPassword } from '../../features/user/userSlice';
import * as S from './Modal.styled';

interface ModalProps {
  title: string;
}

const Login: React.FC<{}> = React.memo(() => {

  const nodeRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState('none');

  return (
    <S.ModalForm 
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (status === 'submitting') return;
      const data = new FormData(e.target as HTMLFormElement);
      setStatus('submitting');
      dispatch(userLogin({
        username: data.get('username')! as string,
        password: data.get('password')!  as string})).unwrap()
      .then((data) => {
        setStatus('none');
        if (data.challengeName === "NEW_PASSWORD_REQUIRED") {
          dispatch(passwordModal(data));
        } else {
          dispatch(closeModal());
        }
      })
      .catch(() => {
        setStatus('error');
      });
    }}>
      <S.Title>LOGIN</S.Title>
      <S.InputRow>
        <div>Username:</div>
        <S.ModalInput type="username" name="username"/>
      </S.InputRow>
      <S.InputRow>
        <div>Password:</div>
        <S.ModalInput type="password" name="password"/>
      </S.InputRow>
      <S.FormSubmit type="submit" value="submit" />
      <CSSTransition
        appear
        timeout={300}
        nodeRef={nodeRef}
        in={status === 'error'}
        mountOnEnter
        unmountOnExit>
        <S.FormError ref={nodeRef}>Incorrect username or password!</S.FormError>
      </CSSTransition>
    </S.ModalForm>
  );
});

const LoginChallenge: React.FC<{challenge: any}> = React.memo(({ challenge }) => {

  const nodeRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState('none');
  console.log(challenge);

  return (
    <S.ModalForm
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (status === 'submitting') { return; } else { setStatus('submitting'); }
      const data = new FormData(e.target as HTMLFormElement);
      if (data.get('password1') !== data.get('password2')) { setStatus('pass_error'); return; }
      const requiredAttrs: {[index: string]: string} = {};
      challenge.challengeParam.requiredAttributes.forEach((attr: string) => {requiredAttrs[attr] = data.get(attr)! as string});
      dispatch(userNewPassword({
        user: challenge,
        newPassword: data.get('password1')! as string,
        requiredAttributes: requiredAttrs
      })).unwrap()
      .then((data) => {
        setStatus('none');
        dispatch(closeModal());
      }).catch((data) => {
        console.log(data);
        setStatus("error");
      })
    }}>
      <S.Title>HI {challenge.username.toUpperCase()}!!! PLEASE FINISH YOUR PROFILE {"<3333"}</S.Title>
      {challenge.challengeParam.requiredAttributes.map((attr: string, i: number) => (
        <S.InputRow key={attr}>
          <div>{attr === "name" ? "Screen name" : attr}:</div>
          <S.ModalInput type="text" name={attr} />
        </S.InputRow>
      ))}
      <S.InputRow>
        <div>New password: </div>
        <S.ModalInput type="password" name="password1"/>
      </S.InputRow>
      <S.InputRow>
        <div>Confirm password: </div>
        <S.ModalInput type="password" name="password2"/>
      </S.InputRow>
      <S.FormSubmit type="submit" value="submit" />
      <CSSTransition
        appear
        timeout={300}
        nodeRef={nodeRef}
        in={status === 'error' || status === "pass_error"}
        mountOnEnter
        unmountOnExit>
        <S.FormError ref={nodeRef}>{status === "pass_error" ? "Passwords don't match!" : "Unidentified error!"}</S.FormError>
      </CSSTransition>
    </S.ModalForm>
  )
});

const Logout: React.FC<{}> = React.memo(() => {

  const nodeRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState('none');
  return (
    <S.ModalForm 
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (status === 'submitting') return;
      setStatus('submitting');
      dispatch(userLogout()).unwrap()
      .then(() => {
        dispatch(closeModal());
      })
      .catch(() => {
        setStatus('error');
      });
    }}>
      <S.Title>PROFILE</S.Title>
      <S.FormSubmit type="submit" value="logout" />
      <CSSTransition
        appear
        timeout={300}
        nodeRef={nodeRef}
        in={status === 'error'}
        mountOnEnter
        unmountOnExit>
        <S.FormError ref={nodeRef}>Error logging out!</S.FormError>
      </CSSTransition>
    </S.ModalForm>
  );
});

const Modal: React.FC<ModalProps> = React.memo(({ title, children }) => {

  const dispatch = useAppDispatch();
  const nodeRef = useRef<HTMLDivElement>(null);
  const modal = useAppSelector(selectModal);
  const [transitionStage, setTransitionStage] = useState('enter');
  const [displayModalType, setDisplayModalType] = useState(modal.type);
  useEffect(() => {
    if (displayModalType === undefined && modal.type !== undefined) {
      setDisplayModalType(modal.type);
    } else if (modal.type !== displayModalType) {
      setTransitionStage('exit');
    }
  }, [modal.type, displayModalType]);

  return (
    <CSSTransition
      appear
      timeout={300}
      nodeRef={nodeRef}
      in={displayModalType !== undefined && transitionStage !== 'exit'}
      classNames='modal'
      onExited={() => {
        setTransitionStage('enter');
        setDisplayModalType(modal.type);
      }}
      mountOnEnter
      unmountOnExit>
      <S.Container ref={nodeRef} onClick={() => { dispatch(closeModal()); }}>
        <S.Modal onClick={(e) => { e.stopPropagation(); }}>
          <S.ModalContained>
            {displayModalType === 'login' ? <Login />
            : displayModalType === 'login-challenge' ? <LoginChallenge challenge={modal.challenge} />
            : <Logout />}
          </S.ModalContained>
        </S.Modal>
      </S.Container>
    </CSSTransition>
  );
});

export default Modal;