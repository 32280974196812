import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { THEMES } from './theme.services';
import type { RootState } from '../../app/store';

interface ThemeState {
  theme: number
}

const initialState: ThemeState = {
  theme: 0
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<number>) => {
      state.theme = action.payload;
    }
  }
});

export const { setTheme } = themeSlice.actions;
export const selectThemeI = (state: RootState) => state.theme.theme;
export const selectTheme = (state: RootState) => THEMES[state.theme.theme];
export default themeSlice.reducer;