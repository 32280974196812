export interface BackgroundImageData {
  imagePath: string
}

export type ThemeData = {
  name: string,
  background: string, // normal background
  background2: string, // secondary background
  background3: string, // tertiary background
  text: string,
  text2: string,
  text3: string,
}

export const THEMES: ThemeData[] = [
  {
    name: 'default',
    background: '#303030',
    background2: '#606060',
    background3: '#202020',
    text: '#d0d0d0',
    text2: '#c0c0c0',
    text3: '#909090'
  },
  {
    name: 'parchment',
    background: '#FCF5E5',
    background2: '#ccbc99',
    background3: '#202020',
    text: '#303030',
    text2: '#606060',
    text3: '#909090'
  },
  {
    name: 'maria',
    background: 'pink',
    background2: '#f888a9',
    background3: '#FF3370',
    text: '#303030',
    text2: '#606060',
    text3: '#3c1b1a'
  },
  {
    name: 'firebrand',
    background: '#54181e',
    background2: '#911a26',
    background3: '#261012',
    text: '#b0b0b0',
    text2: '#c0c0c0',
    text3: '#909090'
  },
];