import React, { useEffect, useRef, useState } from 'react';
import * as S from './Overlay.styled';
import ThemePicker from '../../common/ThemePicker/ThemePicker';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Modal from '../Modal/Modal';
import { CSSTransition } from 'react-transition-group';
import { openModal } from '../../features/modal/modalSlice';
import { selectUser } from '../../features/user/userSlice';
import { CgMailOpen } from 'react-icons/cg';

const Clock: React.FC<{}> = React.memo(() => {

  const [date, setDate] = useState(new Date());

  const tick = () => {
    setDate(new Date());
  }

  useEffect(() => {
    var timerID = setInterval(() => tick(), 1000);
    return function cleanup() {
      clearInterval(timerID);
    }
  });

  return (
    <>{date.toLocaleTimeString()}</>
  );
});

const Overlay: React.FC<{}> = React.memo(({ children }) => {

  const nodeRef = useRef(null);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  return (
    <S.OverlayContainer>
      <S.DashedLine>
        <S.Title><Link to={"/"}>mariapop43.net!</Link></S.Title>
        <S.Location>{location.pathname === "/" ? "/home" : location.pathname}</S.Location>
        <S.ColorDisplay>
          <ThemePicker />
        </S.ColorDisplay>
        <CSSTransition
          appear
          in={user !== undefined}
          nodeRef={nodeRef}
          timeout={300}
          mountOnEnter
          unmountOnExit
        >
          <S.MailBox ref={nodeRef}>
            <CgMailOpen size={18} />
          </S.MailBox>
        </CSSTransition>
        <S.Signature>by evan {"<3"}</S.Signature>
        <S.CurrentTime><Clock /></S.CurrentTime>
        <S.CurrentUser onClick={() => { dispatch(openModal(!user ? "login" : "profile"))}}>USER: {user ? user.name : "none"}</S.CurrentUser>
        {children}
      </S.DashedLine>
      <S.DecalSVG placement={0}>
        <line x1="0" y1="24" x2="0" y2="0" />
        <line x1="24" y1="0" x2="0" y2="0" />
      </S.DecalSVG>
      <S.DecalSVG placement={1}>
        <line x1="0" y1="0" x2="24" y2="0" />
        <line x1="24" y1="24" x2="24" y2="0" />
      </S.DecalSVG>
      <S.DecalSVG placement={2}>
        <line x1="0" y1="0" x2="0" y2="24" />
        <line x1="24" y1="24" x2="0" y2="24" />
      </S.DecalSVG>
      <S.DecalSVG placement={3}>
        <line x1="0" y1="24" x2="24" y2="24" />
        <line x1="24" y1="0" x2="24" y2="24" />
      </S.DecalSVG>
      <Modal title={"LOGIN"} />
    </S.OverlayContainer>
  )
});

export default Overlay;