import React from 'react';
import * as S from './Home.styled';
import GridBackground from '../../common/GridBackground/GridBackground';
import { Link } from 'react-router-dom';

import SORAYAMA from '../../assets/img/sorayama.png';

class WardrobeImage extends React.PureComponent<{ src: string }, {loaded: boolean}> {

  componentMounted: boolean = false;

  constructor(props: {src: string}) {
    super(props);
    this.state = {
      loaded: false
    }
  }

  onImageLoad = () => {
    if (this.componentMounted) {
      this.setState({ loaded: true });
    }
  }

  componentDidMount() {
    const imgSrc = this.props.src;
    if (imgSrc) {
      let img = new window.Image();
      img.src = imgSrc;
      img.onload = this.onImageLoad;
      this.componentMounted = true;
    }
  }

  componentWillUnmount() {
    this.componentMounted = false;
  }

  render() {
    const { src, ...rest } = this.props;
    return (
      <S.WardrobeImg src={src} {...rest} loaded={this.state.loaded} />
    )
  }
}

const HomePage: React.FC<{}> = () => {

  return (
    <S.Container>
      <GridBackground />
      {/* <RandomBackground n={100} /> */}
      <S.Content>
        <S.Maria>
          <S.TitleSpan left={true}>MARIA</S.TitleSpan>
          <S.TitleSpan left={false}>POP43!</S.TitleSpan>
          <S.DotNet>{"(.net)"}</S.DotNet>
        </S.Maria>
        <WardrobeImage src={SORAYAMA} />
        <Link to={"/sonny"}>
          <S.NavLink left>{"<"} SONNY 2048 {"<"}</S.NavLink>
        </Link>
        <Link to={"/"}>
          <S.NavLink>{">"} COMING SOON {">"}</S.NavLink>
        </Link>
      </S.Content>
    </S.Container>
  );
}

export default HomePage;