import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

type ModalType = 'login' | 'login-challenge' | 'profile' | undefined;

interface ModalState {
  type?: ModalType,
  challenge?: any,
}

const initialState: ModalState = {
  type: undefined,
  challenge: undefined
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<ModalType>) => {
      state.type = action.payload;
      state.challenge = undefined;
    },

    passwordModal: (state, action: PayloadAction<any>) => {
      state.type = 'login-challenge';
      state.challenge = action.payload;
    },

    closeModal: (state) => {
      state.type = undefined;
      state.challenge = undefined;
    }
  }
});

export default modalSlice.reducer;
export const { openModal, closeModal, passwordModal } = modalSlice.actions;
export const selectModal = (state: RootState) => (state.modal);
export const selectModalType = (state: RootState) => (state.modal.type);