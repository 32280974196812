import styled from 'styled-components';

export const OverlayContainer = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  padding: 5px;
  z-index: 100;
  transform-origin: 50% 50%;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;

  .location-enter & {
    opacity: 0.3;
    transform: scaleX(0.95) scaleY(0.95);
  }

  .location-enter-active & {
    opacity: 1;
    transform: scaleX(1) scaleY(1);
  }

  .location-exit & {
    opacity: 1;
    transform: scaleX(1) scaleY(1);
  }

  .location-exit-active & {
    opacity: 0.3;
    transform: scaleX(0.95) scaleY(0.95);
  }
`;

export const ColorDisplay = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 20px;
  right: 20px;
  gap: 10px;
`;

export const Title = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  font-family: "Pico Pixel";
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 3px;
  color: ${({ theme }) => theme.text2};
  border-bottom: 2px solid ${({ theme }) => theme.background2};
  pointer-events: all;
  transform-origin: 0% 50%;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scaleX(1.2);
  }

  .location-enter-active & {
    pointer-events: none;
  }

  .location-exit-active & {
    pointer-events: none;
  }
`;

export const Location = styled.div`
  position: absolute;
  top: 45px;
  left: 20px;
  opacity: 0.9;
  font-family: "Pico Pixel";
  text-transform: uppercase;
  color: ${({ theme }) => theme.text2};
  pointer-events: all;
  transform-origin: 0% 50%;
  transition: transform 0.2s ease-in-out;
  cursor: default;

  &:hover {
    transform: scaleX(1.2);
  }
`;

export const Signature = styled.div`
  position: absolute;
  bottom: 15px;
  right: 20px;
  font-family: "Pico Pixel";
  line-height: 1;
  color: ${({ theme }) => theme.text2};
  pointer-events: all;
  transform-origin: 100% 50%;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scaleX(1.2);
  }
`;

export const CurrentTime = styled.div`
  position: absolute;
  bottom: 30px;
  left: 20px;
  font-family: "Pico Pixel";
  line-height: 1;
  color: ${({ theme }) => theme.text2};
  cursor: default;
`;


export const CurrentUser = styled.div`
  position: absolute;
  bottom: 15px;
  left: 20px;
  font-family: "Pico Pixel";
  line-height: 1;
  color: ${({ theme }) => theme.text2};
  pointer-events: all;
  transform-origin: 0% 50%;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scaleX(1.2);
  }
`;

export const DashedLine = styled.div`
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  position: absolute;
  border: 2px dashed rgba(60,60,60,0.9);
  padding: 20px;
`;

export const DecalSVG = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg', 
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
  viewBox: '0 0 24 24'
})<{ placement: number }>`
  position: absolute;
  width: 36px;
  height: 36px;
  ${({ placement }) => placement % 2 === 0 ? `left: ` : `right: `} 15px;
  ${({ placement }) => placement / 4 < 0.5 ? `top: ` : `bottom: `} 15px;
  line {
    stroke: #606060;
    stroke-width: 2px;
  }
`;

export const MailBox = styled.div`
  position: absolute;
  top: 20px;
  right: 50px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  pointer-events: all;
  color: ${({ theme }) => theme.text2 };

  &.enter {
    opacity: 0;
    transform: translateY(-10px);
  }

  &.enter-active {
    opacity: 1;
  }

  &.exit {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
    transform: translateY(-10px);
  }

  &:hover {
    transform: scale(1.1);
  }
`;