import styled from 'styled-components';

export const Container = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: all;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
  background-color: ${({ theme }) => theme.background2}70;

  &.modal-enter {
    background-color: ${({ theme }) => theme.background2}00;
  }

  &.modal-enter-active {
    background-color: ${({ theme }) => theme.background2}70;
  }

  &.modal-exit {
    background-color: ${({ theme }) => theme.background2}70;
  }

  &.modal-exit-active {
    background-color: ${({ theme }) => theme.background2}00;
  }
`;

export const Modal = styled.div`
  position: relative;
  width: 80%;
  max-width: 500px;
  padding: 10px;
  border: 2px ${({ theme }) => theme.background2} dashed;
  transition: all 0.3s ease-in-out;
  pointer-events: all;

  .modal-enter & {
    opacity: 0;
  }

  .modal-enter-active & {
    opacity: 1;
  }

  .modal-exit & {
    opacity: 1;
  }

  .modal-exit-active & {
    opacity: 0;
    transform: translateY(30px);
  }
`;

export const ModalContained = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.background};
  padding: 10px 20px;
`;

export const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const InputRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ModalInput = styled.input`
  flex: 1;
  color: ${({ theme }) => theme.text};
  background: 
      linear-gradient(${({ theme }) => `${theme.background2}, ${theme.background2}`}) center bottom 5px /calc(100% - 10px) 2px no-repeat;
  border: none;
  padding: 10px;
  font-size: calc(var(--base-unit) * 1.5);
`;

export const FormSubmit = styled.input`
  padding: 10px 30px;
  background-color: ${({ theme }) => theme.background2}50;
  color: ${({ theme }) => theme.text};
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  line-height: 1;
  margin-top: 10px;
  border: none;
  font-size: calc(var(--base-unit) * 1.5);

  &:hover {
    transform: scale(1.1);
  }
`;

export const FormError = styled.div`
  position: absolute;
  bottom: -40px;
  left: 50%;
  background-color: #b5484e;
  color: white;
  border-radius: 5px;
  transform: translateX(-50%);
  padding: 5px;
  transition: all 0.3s ease-in-out;

  &.enter {
    opacity: 0;
    transform: translateX(-50%) translateY(-30px);
  }

  &.enter-active {
    opacity: 1;
    transform: translateX(-50%);
  }

  &.exit {
    opacity: 1;
    transform: translateX(-50%);
  }

  &.exit-active {
    opacity: 0;
    transform: translateX(-50%) translateY(-30px);
  }
`;

export const Title = styled.div`
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
`;